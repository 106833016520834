import { render, staticRenderFns } from "./books_the_pragmatic_programmer.vue?vue&type=template&id=90166024&scoped=true&lang=pug"
import script from "./books_the_pragmatic_programmer.vue?vue&type=script&lang=ts"
export * from "./books_the_pragmatic_programmer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90166024",
  null
  
)

export default component.exports